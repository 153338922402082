.calendar-filter {
    z-index: 1;
    position: fixed;
    top: 140px;
    background: white;
    border-top: 1px solid lightgrey;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border: 1px solid lightgrey;
    max-height: 50vh;
    overflow: auto;
    border-radius: 5px;
    
}

.calendar-filter .month {
    display: grid;
    grid-template-columns: repeat(7, 7fr);
    font-size: 13px;
}

.calendar-filter .month .day,
.calendar-filter .month .dummy,
.calendar-filter .month .day-header {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar-filter .month .dummy {
    background-color: white;
}

.calendar-filter .month .dummy.end {
    border-top: 1px solid #d3d3d8;
    margin-top: -1px;
}

.calendar-filter .month .day-header {
    font-size: 0.8em;
    color: grey;
    text-transform: none;
}

.calendar-filter .month .day {
    cursor: pointer;
    border: 1px solid #d3d3d8;
    border-right-color: transparent;
    margin-top: -1px;
    color: rgb(79, 79, 101);
    opacity: 1;
    font-size: 13px;
}

.calendar-filter .month .day.first-week {
    margin-top: 0;
}

.calendar-filter .month .day.sunday {
    border-right-color: #d3d3d8;
}

.calendar-filter .month .day.last + .dummy {
    border-left: 1px solid #d3d3d8;
}

.calendar-filter .month-header {
    text-transform: none;
    text-align: center;
    color: rgb(79, 79, 101);
    font-size: 13px;
    padding: 10px;
}

.calendar-filter .month .day.start-date,
.calendar-filter .month .day.end-date,
.calendar-filter .month .day.in-between-date {
    color: white;
    background-color: var(--resaltado);
}
