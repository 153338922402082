@media(max-width: 992px) {
    .admin .sidebar {
        left: -80vw;
        z-index: 1020;
        transition: 300ms;
        bottom: 0;
        top: 52px;
        overflow: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
   }

   .admin .sidebar::-webkit-scrollbar { 
       display: none;  /* Safari and Chrome */
    }

    .admin .sidebar.active {
        left: 0;
    }

    #main > .admin .content {
        margin-left: 0;
    }

    .admin .sidebar-toggle-button {
        position: fixed;
        z-index: 1021;
        top: 0.35em;
        left: 0.4em;
        width: 44px;
        height: 44px;
    }

    .admin .sidebar-toggle-button .material-symbols-rounded {
        font-size: 2.5em;
    }

    .admin .sidebar-toggle-button img {
        width: 44px;
        height: 44px;
    }

    .admin .sidebar-menu-backdrop {
        pointer-events: none;
        opacity: 0;
        position: fixed;
        z-index: 1020;
        top: 52px;
        left: 0;
        right: 0;
        bottom: 0;
        background: black;
        transition: 300ms
    }

    body.admin.menu-open .sidebar-menu-backdrop {
        pointer-events: all;
        opacity: 0.5;
    }

    .admin .content .admin-bloque.columnas {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .admin-faq .faq-header > span {
        text-align: left;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .admin-faq .faq-header {
        height: 7em;
    }

    .admin-page .header, .admin .edit-page .header {
        flex-direction: column-reverse;
        height: unset;
        padding: 5px;
    }

    .admin-page .header, .admin .edit-page .header .right,
    .admin-page .header, .admin .edit-page .header .right > div {
        width: 100%;
    }
    
    .admin-page .header .right {
        width: 100%;
    }

    .admin-page.admin-reservas .combo-select .options, .admin-page.admin-reservas .combo-select .selected-option {
        max-width: unset;
        margin-bottom: 5px;
    }

    body.scrolled .admin-page .header,
    body.scrolled .admin .edit-page .header {
        top: 51px;
        left: 0;
        right: 0;
        height: 50px;
        background-color: white;
    }

    .admin-page .header .left {
        display: flex;
    }

    .admin-page .header .left .btn {
        width: calc(50vw - 12px);
    }

    .admin-page .header .left .btn:last-child {
        margin-right: 0;
    }
}