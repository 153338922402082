@media(min-width: 1100px) {
	body .admin .edit-page.editar-evento .header + .admin-bloque {
		grid-template-columns: 1fr 2fr !important;
	}
}

@media(min-width: 1300px) {
	body .admin .edit-page.editar-evento .header + .admin-bloque {
		grid-template-columns: 4fr 3fr !important;
	}
}

@media(min-width: 1500px) {
	body .admin .edit-page.editar-evento .header + .admin-bloque {
		grid-template-columns: 5fr 3fr !important;
	}
}
.admin .edit-page.editar-evento .descripcion {
	height: 300px;
}

.admin .edit-page.editar-evento .precio {
	margin-left: 0.35em;
}

.admin .edit-page.editar-evento .duracion {
	border: 1px solid #d7e0e2;
	background: white;
	margin: 0 0 0 0.35em;
	height: 36px;
    margin-top: -2px;
}

.admin .edit-page.editar-evento .toggles {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 20px 0;
}

@media(max-width: 1500px) {
    .admin .edit-page.editar-evento .toggles {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
    }

    .admin .edit-page.editar-evento .toggles > div {
        width: 100%;
        justify-content: space-between;
    }
}

.admin .edit-page.editar-evento .toggles > div {
	display: flex;
	align-items: center;
}

.admin .edit-page.editar-evento .toggles .form-toggle {
	margin-left: 0.35em;
}

.admin .edit-page.editar-negocio .json-editor .plus-wide,
.admin .edit-page.editar-evento .accordion-tab-container {
	margin-left: 0;
	margin-right: 0;
}

.admin .edit-page.editar-evento .day-select,
.admin .edit-page.editar-evento .day-select a:first-child {
	margin-left: 0;
}

.admin .edit-page.editar-evento .day-select {
	margin-bottom: 25px;
}

.admin .edit-page.editar-evento .timetable-editor {
	margin-left: 5px;
}

.admin .edit-page.editar-evento .timetable-editor .buttons {
	display: flex;
	align-items: center;
	position: relative;
}

.admin .edit-page.editar-evento .timetable-editor .buttons > div {
	cursor: pointer;
	user-select: none;
}

.admin .edit-page.editar-evento .timetable-editor .buttons .plus {
	position: absolute;
	right: 0;
	bottom: 7px;
}

.admin .edit-page.editar-evento .timetable-editor .buttons img {
	filter: invert(1) brightness(0.5);
	padding: 6px;
	width: 30px;
	height: 30px;
	border: 1px solid black;
	border-radius: 15px;
	margin-right: 10px;
	display: block;
}

.admin .edit-page.editar-evento .timetable-editor .buttons > div:hover img {
	filter: none;
}

.admin .edit-page.editar-evento .timetable-editor .slot {
	display: flex;
	align-items: center;
}

.admin .edit-page.editar-evento .day-select.disabled,
.admin .edit-page.editar-evento .timetable-editor.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.admin .edit-page.editar-evento .delete-row {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 15px;
	cursor: pointer;
}

.admin .edit-page.editar-evento .programar-container {
	position: relative;
}

.admin .edit-page.editar-evento .programar-container .programar-enabled {
	position: absolute;
	right: 0;
	top: 20px;
}

.admin .edit-page.editar-evento .programar-disabled-notice {
    position: absolute;
    z-index: 1;
    background: #ffffffdd;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 20px;
    top: calc(50% + 28px);
    transform: translateY(-50%);
    animation: programar-disabled-notice 300ms forwards;
}

@keyframes programar-disabled-notice {
    0% {
        opacity: 0;
        transform: translateY(15px);
    }
    100% {
        opacity: 1;
        transform: translateY(-50%);
    }
}

.admin .edit-page.editar-evento h2.programar-disponibilidad {
	margin-top: 15px;
	margin-left: -20px;
    margin-right: -20px;
    border-top: 1px solid lightgrey;
    padding-top: 10px;
    padding-left: 20px;
}

.admin .edit-page.editar-evento .error h2.programar-disponibilidad {
    color: crimson;
}

.admin .edit-page.editar-evento .timetable-editor .heading {
	display: flex;
    align-items: center;
}

.admin .edit-page.editar-evento .timetable-editor .heading > div,
.admin .edit-page.editar-evento .timetable-editor .slot > * {
	width: 35%;
	max-width: 176px;
}

.admin .edit-page.editar-evento .timetable-editor .input-select {
	width: 25%;
	max-width: 126px;
}

.admin .edit-page.editar-evento .timetable-editor .delete-row {
	width: 1em;
}

.admin .edit-page.editar-evento .admin-bloque.encuestas .columna {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 4fr);
}

.admin .edit-page.editar-evento .admin-bloque.encuestas .columna .category {
    padding: 15px 20px;
    background-color: #f6f6f6;
}

@media(max-width: 1550px) {
    .admin .edit-page.editar-evento .admin-bloque.encuestas .columna {
        grid-template-columns: repeat(3, 3fr);
    }
}

.admin .edit-page.editar-evento .estadisticas .bar-chart.chart-container > div {
	display: flex;
	flex-direction: column;
	margin-top: 2em;
}