.image-upload {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
}

.image-upload img {
	user-select: none;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.image-upload input[type="file"] {
	visibility: hidden;
	position: absolute;
	width: 1px;
	z-index: -1;
}

.image-upload.empty {
	background-color: #f6f6f6;
	border: 1px solid lightgrey;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 300px;
}

.image-upload.empty img {
	display: none;
}

.image-upload .add-icon {
	display: none;
	pointer-events: none;
}

.image-upload:hover .add-icon,
.image-upload.empty .add-icon {
	display: block;
	position: absolute;
}

.image-upload.empty:hover .add-icon,
.image-upload:hover .add-icon {
	color: white;
}

.image-upload:hover img {
	filter: brightness(0.5);
}

.image-upload .overlay {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: black;
	transition: 300ms;
}

.image-upload:hover .overlay {
	opacity: 0.75;
}

