@media(max-width: 992px) {
    .admin-estadisticas .kpi-container {
        display: flex;
        flex-direction: column;
    }

    .estadisticas .legend-container, .admin-page.admin-estadisticas .legend-container {
        margin-left: 0;
    }

    .admin-page.admin-estadisticas .admin-bloque.columnas .columna {
        min-height: calc(25vh);
    }
}