.admin-negocios .event-carousel .item-container.placeholder {
	height: 220px;
	background-color: #eee;
}

.admin-negocios .evento-grid {
	display: grid;
	grid-template-columns: repeat(5, 5fr);
	gap: 25px;
}

.admin-negocios .fila.venue-type.disabled .evento-grid {
    opacity: 0.5;
}

.admin-negocios .evento-grid .carrusel-main-evento {
	height: 380px;
	box-sizing: border-box;
    user-select: none;
    transition: none;
    transition: border-bottom 500ms;
	position: relative;
    display: flex;
    flex-direction: column;
}

.admin-negocios .disabled-tag {
	z-index: 1;
	right: 5px;
	top: 0;
	position: absolute;
	text-transform: uppercase;
	font-size: 11px;
	margin-right: 5px;
	display: inline-flex;
	padding: 2px 7px;
	border-radius: 5px;
	background-color: var(--resaltado);
	color: white;
	justify-content: space-evenly;
	align-items: center;
	font-weight: bold;
	margin-top: 5px;
}

.admin-negocios .evento-grid .carrusel-main-evento.dragged-negocio {
    filter: grayscale(1) opacity(0.75);
}

.admin-negocios .evento-grid.dragging .carrusel-main-evento:hover {
    border: 1px solid transparent;
    border-left: 10px solid var(--resaltado-transparente);
    padding-left: 5px;
    margin-left: -14px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
}

.admin-negocios .carrusel-main-evento.add-new {
	display: flex;
	align-items: center;
	justify-content: center;
}

.admin-negocios .row-actions {
	display: flex;
	align-items: center;
	margin: 0 -20px 20px -20px;
	padding: 5px 20px;
	border-bottom: 1px solid lightgrey;
}

.admin-negocios .row-actions h3 {
	margin-bottom: 0;
}

.admin-negocios .row-actions .toggle-container {
	margin-left: auto;
    margin-right: 1em;
    padding-bottom: 5px;
}

.admin-negocios .admin-bloque.filas .fila {
	padding-top: 0;
}

.admin-negocios .row-actions > div {
	position: relative;
}

.admin-negocios .row-actions .highlight-type .material-symbols-rounded,
.admin-negocios .row-actions .move-type-up .material-symbols-rounded,
.admin-negocios .row-actions .move-type-down .material-symbols-rounded {
	font-size: 30px;
	margin-right: 20px;
}

.admin-negocios .custom-field {
	margin-right: 20px;
	width: 250px;
}

.admin-negocios .modal-encuesta-satisfaccion .modal-content-area {
    padding: 15px;
    position: relative;
}

.admin-negocios .modal-encuesta-satisfaccion .modal-content-area .add-button {
    position: absolute;
    top: 15px;
    right: 15px;
}

.admin-negocios .modal-encuesta-satisfaccion:not(.empty) .add-button {
    border: 1px solid lightgrey;
    border-radius: 5px;
    bottom: 15px;
    left: 15px;
    top: unset;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background-color: white;
}

.admin-negocios .modal-encuesta-satisfaccion .custom-field {
    margin-left: 0;
}

.admin-negocios .modal-encuesta-satisfaccion:not(.empty) .add-button:hover {
    background: #f6f6f6;
    border-color: grey;
}

.admin-negocios .modal-encuesta-satisfaccion:not(.empty) .modal-content-area {
    padding-bottom: 60px;
    padding-top: 0;
}

.admin-negocios .modal-encuesta-satisfaccion .modal-content-area .line {
    padding: 5px 0;
    border-bottom: 1px solid lightgrey;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-negocios .modal-encuesta-satisfaccion .modal-content-area .line:last-child {
    border-bottom: none;
}

.admin-negocios .carrusel-main-evento .details {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.admin-negocios .carrusel-main-evento .details .star-meter {
	float: left;
	margin-left: -0.3vw;
}

.admin-negocios .carrusel-main-evento .details .star-meter > span {
	font-size: 1.8vw;
    margin-top: 5px;
}

.admin-negocios .carrusel-main-evento .details .numeric-value {
	display: inline-block;
    background: var(--resaltado-claro);
    color: var(--resaltado);
    padding: 0 5px;
    border-radius: 3px;
    margin-left: 10px;
    font-size: 1.1vw;
}

.admin-negocios .average-price {
	text-transform: uppercase;
    font-size: 11px;
    margin-right: auto;
    display: inline-flex;
    padding: 2px 7px;
    border-radius: 5px;
    background-color: var(--resaltado);
    color: white;
    justify-content: space-evenly;
    align-items: center;
    font-weight: bold;
    margin-top: auto;
	margin-bottom: auto;
}

.admin-negocios .rating-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin-negocios .row-actions > .custom-field ~ div:not(.delete-container) .material-symbols-rounded {
    margin-right: 20px;
    font-size: 30px;
}

.admin-negocios .row-actions > .custom-field ~ div:not(.delete-container) .material-symbols-rounded.sort-by-name {
    font-size: 35px;
}

.admin-negocios .row-actions .sort-button {
    opacity: 0.5;
}

.admin-negocios .row-actions .sort-button.active {
    opacity: 1;
}

@media(max-width: 1500px) {
    .admin-negocios .evento-grid {
        grid-template-columns: repeat(4, 4fr);
    }
}