.restaurantes .container.search {
    display: flex;
    position: absolute;
    bottom: -50px;
    z-index: 3;
    font-family: "Circular", sans-serif;
}

.restaurant-search {
    display: flex;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
    margin: 2em auto 1em;
    padding: 1em;
    border-radius: 5px;
    border: 1px solid #f5f3ef;
    background-color: white;
    border-radius: 40px;
    box-shadow: 0 0 10px rgb(0 0 0 / 1%);
    position: relative;
}

.restaurant-search input {
    flex: 1;
}

.restaurant-search select {
    margin-left: 1em;
}

.restaurant-search select,
.restaurant-search input {
    padding: 5px;
    height: 38px;
}

.restaurant-search .calendar-container {
    position: absolute;
    width: 650px;
    background: white;
    z-index: 2;
    padding: 1em;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    animation-name: calendar-appear;
    animation-duration: 100ms;
    animation-timing-function: ease-in-out;
    opacity: 1;
    margin-top: 0;
}

.restaurant-search .price-rating-container,
.restaurant-search + .modal-outer .price-rating-container {
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.restaurant-search + .modal-outer .price-rating-container {
    align-items: stretch;
    height: 53px;
}

.restaurant-search .price-rating-container a,
.restaurant-search + .modal-outer .price-rating-container a {
    transition: 300ms;
    color: black;
    font-family: "Circular", sans-serif;
    padding: 0 0.5em;
    margin-right: 5px;
    position: relative;
    line-height: 1.45;
    border-radius: 5px;
    background: white;
    border: 1px solid rgb(211, 211, 216);
    height: 39px;
    display: inline-flex;
    align-items: center;
    width: 55px;
    justify-content: center;
}

.restaurant-search + .modal-outer .price-rating-container a {
    background: white;
    border-color: rgb(211, 211, 216);
    display: flex;
    align-items: center;
    color: black;
    width: 46px;
    justify-content: center;
    margin-right: 0;
    margin-left: 5px;
    height: auto;
    font-size: 14px;
}

.restaurant-search .price-rating-container a:hover,
.restaurant-search + .modal-outer .price-rating-container a:hover {
    color: #333;
}

.restaurant-search .price-rating-container a.active,
.restaurant-search + .modal-outer .price-rating-container a.active {
    color: white;
    background: #333;
    border-color: #333;
}

.search-calendar-modal .modal-content-area {
    padding: 2rem;
}

.search-calendar-modal .period-button-container {
    border: 1px solid #d3d3d8;
    border-radius: 5px;
    height: 72px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;
}

.search-calendar-modal .period-button-container a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.search-calendar-modal .period-button-container a.active {
    background-color: #333;
    color: #d3d3d8;
}

.search-calendar-modal .period-button-container a:nth-child(2) {
    border-left: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
    border-radius: 0;
}

.search-calendar-modal .period-button-container a:nth-child(1) {
    border-radius: 5px 0 0 5px;
}

.search-calendar-modal .period-button-container a:nth-child(3) {
    border-radius: 0 5px 5px 0;
}

.restaurant-search .search-selector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 1em 0 0;
    margin: auto 0 auto 1em;
    border: 1px solid #d3d3d8;
    border-width: 0 1px 0 0;
    height: 42px;
    cursor: pointer;
}

.restaurant-search .custom-dropdown {
    border: 1px solid #d3d3d8;
    border-width: 0 1px 0 0;
}

.restaurant-search .search-selector:last-child {
    border-right-width: 0;
    padding-right: 0;
}

.restaurant-search .date-selector-container {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 42px;
    margin-right: 0.5em;
    cursor: pointer;
    background: white;
    margin-left: 1em;
}

.restaurant-search .clear-date-button {
    font-size: 18px;
    margin: 0 0.5em 0 0.5em;
    cursor: pointer;
}

.restaurant-search .search-selector-title {
    width: 100%;
    font-size: 0.9em;
    font-weight: bold;
}

.restaurant-search .pax-selector-container,
.restaurant-search + .modal-outer .pax-selector-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.restaurant-search .pax-selector .tock-icon,
.restaurant-search + .modal-outer .pax-selector .tock-icon {
    font-size: 25px;
    background: transparent;
    border-radius: 23px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0 0 0 10px;
}

.restaurant-search .pax-selector .tock-icon:hover,
.restaurant-search + .modal-outer .pax-selector .tock-icon:hover {
    background-color: #f6f6f6;
}

.restaurant-search + .modal-outer .price-rating {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
}

.restaurant-search .pax-selector > span {
    margin: 0 0.5em 0 0;
}

.restaurant-search + .modal-outer .pax-selector span {
    flex: 1;
}

.restaurant-search + .modal-outer .pax-selector a {
    min-height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    text-align: center;
}

.restaurant-search + .modal-outer .pax-selector a:last-child {
    margin-right: 10px;
}

.restaurant-search + .modal-outer .price-and-pax-selectors {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.restaurant-search + .modal-outer .pax-selector-container {
    flex: 1;
}

.restaurant-search + .modal-outer .pax-selector {
    font-size: 14px;
    padding: 0 0 0 15px;
    border-radius: 5px;
    border: 1px solid rgb(211, 211, 216);
    display: flex;
    flex: 1;
    align-items: center;
}

.search-city-modal .modal-content-area {
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 15px;
    padding: 15px;
    padding-bottom: 190px;
    padding-top: 30px;
    height: auto;
}

.search-city-modal .city-button {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 7em;
    border-radius: 5px;
    margin: 0;
    position: relative;
}

.search-city-modal .city-button:not(.proximamente):not(.disabled) {
    cursor: pointer;
}

.search-city-modal .city-button.proximamente,
.search-city-modal .city-button.disabled {
    opacity: 0.5;
    filter: grayscale(1);
}

.search-city-modal .city-button .city-name-tag {
    position: absolute;
    text-transform: uppercase;
    left: 10px;
    top: 10px;
    font-family: "Circular";
    font-size: 13px;
    padding: 5px 15px;
    width: auto;
    height: auto;
    border-radius: 3px;
    color: white;
    background: var(--resaltado);
}

@keyframes calendar-appear {
    from {
        opacity: 0;
        margin-top: -20px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}

@media (max-width: 1050px) {
    .search-city-modal .modal-content-area {
        padding: 0;
        display: block;
    }

    .search-city-modal .city-button {
        margin: 15px;
    }

    .restaurant-search .search-selector:first-child {
        border-right: 0;
    }

    .restaurant-search .custom-dropdown {
        border-width: 0;
    }

    .restaurant-search {
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        justify-content: center;
        width: 100%;
    }

    .restaurantes .container.search {
        bottom: -10px;
    }

    .restaurant-search select {
        margin: 1em 0 0 0;
    }

    .period-button-container {
        text-align: center;
    }

    .restaurantes-banner::before {
        bottom: -51px;
        max-width: 55%;
    }

    .restaurantes-banner {
        height: 450px;
    }

    .search-separator {
        width: 1px;
        background: #f5f3ef;
    }

    .search-calendar-modal .modal-content-area {
        padding: 16px;
    }
}

@media (max-width: 767px) {
    .restaurant-search .search-selector {
        flex: 1;
        align-items: center;
    }
}
