.form-input {
    position: relative;
}

.form-input label {
    position: absolute;
    pointer-events: none;
    transition: 300ms;
    top: 12px;
    left: 12px;
    font-size: 13px;
    opacity: 0.5;
}

.form-input.checkbox label {
    position: relative;
    pointer-events: all;
    opacity: 1;
    display: flex;
    align-items: center;
    left: 0;
    cursor: pointer;
    user-select: none;
}

.form-input.checkbox label > input[type=checkbox]:first-child {
    margin-right: 0.5em;
}

.form-input textarea {
    height: 100%;
    flex: 1;
}

.form-input.type-textarea {
    display: flex;
    flex-direction: column;
}

.form-input input:focus + label,
.form-input input.non-valid:focus + label,
.form-input input.non-empty + label,
.form-input textarea:focus + label,
.form-input textarea.non-valid:focus + label,
.form-input textarea.non-empty + label {
    transform: none;
    font-size: 12px;
    top: 5px;
    color: #d3d3d8;
    outline: none;
}

input:not([type="checkbox"]),
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
}

.form-input input:not([type="checkbox"]),
.form-input textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    outline: none;
    padding: 20px 8px 2px;
    width: 100%;
    border: 1px solid #d3d3d8;
    border-width: 0 0 1px 0;
    /* border-radius: 5px; */
    font-size: 16px;
    margin: 8px 0 0 0;
    display: block;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}

.form-input .validation-message {
    color: var(--error);
    margin: 3px 0 6px;
    line-height: 1.66;
    font-size: 12px;
    position: absolute;
    z-index: 1;
}

.form-input input.non-valid,
.form-input textarea.non-valid {
    border-color: var(--error);
}

.form-input input.non-valid + label,
.form-input textarea.non-valid + label {
    color: var(--error);
}

.form-input.half-width {
    width: calc(50% - 5px);
    display: inline-block;
}

.form-input.half-width.left {
    margin-right: 5px;
}

.form-input.half-width.right {
    margin-left: 5px;
}

.form-input.half-width label {
    top: 15px;
}

.form-input.half-width input {
    margin-top: 2px;
}

.form-input.half-width input:focus + label,
.form-input.half-width input.non-valid:focus + label,
.form-input.half-width input.non-empty + label,
.form-input.half-width textarea:focus + label,
.form-input.half-width textarea.non-valid:focus + label,
.form-input.half-width textarea.non-empty + label {
    top: 9px;
}

.form-input.select {
    position: relative;
    margin: 0;
    padding: 20px 8px 2px;
    width: 100%;
    border: 1px solid #d3d3d8;
    border-width: 0 0 1px 0;
    font-size: 16px;
    display: block;
    min-width: 0;
    background: none;
    box-sizing: border-box;
    position: relative;
}

.form-input.select .options-list {
    position: absolute;
    background: white;
    width: 100%;
    max-height: 333px;
    overflow-y: auto;
    border: 1px solid #efefef;
    z-index: 3;
    left: 0;
    bottom: 25px;
    box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}

.form-input.select .options-list .option {
    padding: 6px 8px;
    border-bottom: 1px solid #efefef;
    cursor: pointer;
}

.form-input.select .options-list .option.selected,
.form-input.select .options-list .option:hover {
    background: var(--resaltado);
    color: white;
}

.form-input.select .options-list .option:last-child {
    border-bottom: none;
}

.form-input.select .selected-option {
    cursor: pointer;
}

.form-input.select label {
    position: absolute;
    pointer-events: none;
    transition: 300ms;
    left: 12px;
    opacity: 0.5;
    font-weight: 400;
    line-height: 1.5;
    display: block;
    font-size: 12px;
    top: -3px;
    color: #d3d3d8;
    outline: none;
}

.form-input .visibility-button {
    position: absolute;
    bottom: 3px;
    right: 7px;
}
