.custom-dropdown {
    position: relative;
    cursor: pointer;
}

.custom-dropdown.open .dropdown-options {
    transition: top 300ms;
    top: 0px;
}

.custom-dropdown .dropdown-option {
    padding: 10px 20px;
}

.custom-dropdown .dropdown-option:not(.selected) {
    display: none;
}

.custom-dropdown.open .dropdown-option:not(.selected) {
    display: block;
}

.custom-dropdown.open .dropdown-options {
    position: absolute;
    background: white;
    border-radius: 10px;
    border: 1px solid #f5f3ef;
    width: 244px;
    z-index: 2;
    top: 50px;
}
