.json-editor .list-col,
.json-editor .custom-field,
.json-editor .custom-field > div,
.json-editor textarea {
	display: flex;
	flex-direction: column;
	flex: 1;
    position: relative;
}

.json-editor .list-col.half-column {
	float: left;
}

.json-editor .list-col.half-column + .list-col {
	padding-left: 1em;
}

.json-editor .list-col.half-column + .list-col + .list-col {
	clear: both;
}

.json-editor .custom-field .max-length-indicator {
    z-index: 1;
    bottom: 1px;
    right: 1px;
}

.json-editor .json-list-edit {
	display: grid;
	grid-template-columns: 2fr 2fr;
	gap: 15px;
	padding-top: 5px;
}

.json-editor .json-list-edit .plus-wide {
	margin-top: 0;
	margin-bottom: auto;
}

.json-editor .custom-field .image-upload {
	min-height: 100px;
}

.json-editor .accordion-tab-container {
	position: relative;
}

.json-editor .accordion-tab-container .right-side {
	position: absolute;
	right: 55px;
	justify-content: flex-end;
	pointer-events: none;
	max-height: 35px;
}

.json-editor .accordion-tab-container .right-side .minus {
	pointer-events: all;
}

.json-editor .json-list-edit .accordion-tab-container {
	padding-top: 0;
}

.json-editor .json-list-edit .list-item .accordion-tab-container {
	padding-top: 0;
}

.json-editor .json-list-edit .list-item:nth-child(odd) .accordion-tab-container,
.json-editor .json-list-edit .accordion-tab-container:nth-child(odd),
.json-editor .json-list-edit .plus-wide:nth-child(odd) {
	margin-right: 0;
}

.json-editor .json-list-edit .list-item:nth-child(even) .accordion-tab-container,
.json-editor .json-list-edit .accordion-tab-container:nth-child(even),
.json-editor .json-list-edit .plus-wide:nth-child(even) {
	margin-left: 0;
}

.json-editor .span-two-columns {
	grid-column-start: 1;
    grid-column-end: 3;
}

.json-editor .plus-wide {
	display: block;
	border: 1px solid lightgrey;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 10px 0;
	margin: 15px 50px 0;
	background-color: white;
}

.json-editor .plus-wide:hover {
	background: #f6f6f6;
	border-color: grey;
}

.json-editor .plus-wide.disabled,
.json-editor .plus-wide.disabled:hover {
	opacity: 0.5;
	cursor: default;
	background-color: #f6f6f6;
	border-color: lightgrey;
}

.json-editor .custom-field.type-number > div {
    flex-direction: row;
}

.json-editor .list-col.toggleable .form-toggle {
    position: absolute;
    right: 0;
    top: 17px;
    z-index: 1;
}

.json-editor .list-col.toggleable .form-toggle:not(.active) + .custom-field *:not(label) {
    opacity: 0;
    pointer-events: none;
    display: none;
}

@media(max-width: 992px) {
	.json-editor .json-list-edit {
		display: flex;
		flex-direction: column;
	}

	.json-editor .json-list-edit .accordion-tab-container {
		margin-left: 0;
		margin-right: 0;
	}

	.json-editor .accordion-tab-container .right-side {
		right: 29px;
		max-height: 23px;
	}
}
