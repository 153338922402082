.accordion-tab {
    flex: 1;
}

.accordion-tab.disabled {
	opacity: 0.5;
}

.accordion-tab .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    background: white;
}

.accordion-tab.error .heading {
    border-color: crimson;
    box-shadow: 0px 0px 2px crimson;
}

.accordion-tab.disabled .heading {
	cursor: default;
}

.accordion-tab:not(.disabled) .heading:hover {
    background: #f6f6f6;
}

.accordion-tab:not(.active):not(.disabled) > .heading:hover {
    border-color: grey;
}

.accordion-tab.active > .heading {
    border-radius: 5px 5px 0 0;
    border-bottom-color:rgb(230, 230, 230);
}

.accordion-tab.active > .contents {
    background: white;
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 5px 5px;
    border-color: lightgrey;
    border-style: solid;
    padding: 0.5em 15px 15px 15px;
}

.accordion-tab.error.active .contents {
    border-color: crimson;
}

.accordion-tab-container {
    display: flex;
    margin-left: 50px;
    margin-right: 50px;
	padding-top: 15px;
}

.accordion-tab-container .right-side {
    display: flex;
    height: 100%;
    width: 50px;
    margin-left: 16px;
    margin-bottom: auto;
    margin-top: 10px;
}

.accordion-tab-container .material-symbols-rounded {
    font-size: 30px;
    margin-right: 5px;
}

@media(max-width: 992px) {
    .accordion-tab-container{
        padding-top: 7px;
    }

    .accordion-tab .heading {
        align-items: flex-start;
        padding: 5px 7px;
    }

    .accordion-tab.active > .contents {
        padding: 0.25em 7px 7px 7px;
    }

    .accordion-tab-container .right-side {
        margin-top: 6px;
    }

    .accordion-tab-container .material-symbols-rounded {
        margin-right: 0;
        font-size: 20px;
    }
}
