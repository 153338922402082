.preorder .booking-sidebar {
	margin-top: 0px;
	position: fixed;
	top: 150px;
	opacity: 0;
	transition: opacity 300ms, top 250ms;
}

@media(min-width: 993px) {
	.preorder .preorder.container {
		margin-top: 27px;
	}
}

.preorder .booking-sidebar .sidebar-quantity {
	width: 21px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--resaltado);
	border-radius: 5px;
	color: white;
	font-size: 12px;
	text-align: center;
	margin-right: 0.5em;
}

.preorder .booking-sidebar .details-line {
	display: flex;
	align-items: center;
}

.preorder .booking-sidebar .details-line > span:first-child {
	margin-left: -5px;
	margin-right: 5px;
}

.preorder .booking-sidebar .change-order-link {
	margin-top: 1em;
}

.preorder .booking-sidebar .total-price > span:last-child {
	margin-left: auto;
}

.preorder .booking-sidebar .preorder-selection-information {
	border-top: 1px solid lightgrey;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: 15px;
	padding: 13px 15px 0 15px;
	font-size: 13px;
}

.preorder .booking-sidebar .preorder-selection-information .title {
	font-weight: bold;
	padding-right: 50px;
}

.preorder .booking-sidebar .preorder-selection-information .address {
	color: grey;
}

.preorder .booking-sidebar .preorder-selection-information .details {
	display: flex;
	align-items: center;
}

.preorder .booking-sidebar .preorder-selection-information .item {
	position: relative;
	margin-bottom: 13px;
	border-bottom: 1px solid #f0f0f0;
	padding-bottom: 13px;
}

.preorder .booking-sidebar .preorder-selection-information .item .delete-button {
	width: 19px;
	height: 19px;
	background-color: var(--resaltado-transparente);
	cursor: pointer;
	border-radius: 9.5px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	top: 0;
}

.preorder .booking-sidebar .preorder-selection-information .item .delete-button:hover {
	background-color: #f0f0f0;
}

.preorder .booking-sidebar .preorder-selection-information .details > div {
	margin-right: 0.5em;
	display: flex;
	align-items: center;
}

.preorder .booking-sidebar .preorder-selection-information .item:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.preorder .booking-sidebar .preorder-selection-information .extras {
	margin-top: 5px;
}

.preorder .booking-sidebar .preorder-selection-information .extra-row {
	display: flex;
	align-items: center;
	flex: 1;
	position: relative;
}

.preorder .booking-sidebar .preorder-selection-information .extra-row > span:first-child {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--resaltado);
	border-radius: 3px;
	width: 16px;
	height: 16px;
	font-size: 10px;
	margin-right: 10px;
}

.preorder .booking-sidebar .preorder-selection-information .extra-row > span:last-child {
	position: absolute;
	right: 0;
}

.preorder .booking-sidebar .preorder-selection-information .qty {
	width: 21px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--resaltado);
    border-radius: 5px;
    color: white;
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
	margin-right: 0.5em;
}

.preorder .modal-outer {
	height: 100vh;
	top: 0;
}

.preorder .booking-sidebar .boton-confirmar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 100%;
	margin: 1em auto 4em;
	position: relative;
}

.preorder .booking-sidebar .boton-confirmar.sin-extras {
	justify-content: center;
}

.preorder .booking-sidebar .boton-confirmar .button-total {
	margin-left: 1em;
}

.preorder-custom-header {
	display: none;
}

.preorder .event-information .name {
	font-weight: bold;
	text-transform: uppercase;
}

.preorder .event-information .image {
	background-position: center;
	background-size: cover;
	height: 133px;
	margin: -17px -15px 15px;
	border-radius: 3px 3px 0 0;
}

.preorder .event-information .address-name {
	font-size: 13px;
	text-transform: uppercase;
}

.preorder .details-line.availability-description {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: bold;
	margin-left: 22px;
	margin-bottom: 5px;
}

.preorder .otros .item.evento-item > div:first-child {
	display: flex;
	flex-direction: column;
}

.preorder .otros .evento-iconos {
	display: flex;
	align-items: center;
	margin: auto 0;
}

.preorder .otros .evento-iconos .icon-image {
    width: 32px;
    height: 32px;
}

.preorder .otros .item.evento-item h3 {
	text-transform: uppercase;
}

.preorder .otros .item.evento-item h4 {
	font-size: 13px;
	font-weight: 400;
	color: grey;
	text-transform: uppercase;
	line-height: 1;
}

.preorder-selector .otros .item.evento-item .item-price {
	position: unset;
	bottom: unset;
	margin-top: auto;
	margin-right: auto;
	font-weight: 600;
	font-size: 11px;
	text-transform: uppercase;
	color: white;
}
.categorized-scroller .items .otros h2 {
	font-size: 19px;
	margin-bottom: 10px;
	opacity: 0.5;
	color: var(--resaltado);
	height: 32px;
	font-weight: 700;
	text-transform: uppercase;
}

.modal-venue .header-image {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 25px;
	height: 350px;
	min-height: 150px;
	width: 100%;
}

.modal-venue h1 {
	text-transform: uppercase;
	font-size: 28px;
	margin-bottom: 0;
}

.modal-venue .inner {
	margin: 0 25px;
}

.modal-extras .items,
.modal-venue .items {
	display: grid;
	gap: 15px;
}

.modal-venue .items {
	grid-template-columns: 1fr;
}

.modal-extras.extras-upselling .items {
	grid-template-columns: 2fr 2fr;
}

.modal-venue .items {
	margin-top: 15px;
}

.modal-venue .items + .event-description {
	border-top: none;
}

.modal-extras .item {
	border: 1px solid var(--resaltado-transparente);
	display: flex;
	padding: 0 0 0 15px;
	color: #666;
	box-sizing: border-box;
	height: 150px;
}

.modal-venue .item {
	border: 1px solid var(--resaltado-transparente);
	transition: 300ms;
	padding-bottom: 2px;
	display: flex;
	justify-content: space-evenly;
	color: #666;
	cursor: pointer;
	position: relative;
	margin-bottom: 2px;
	flex-direction: column;
	box-sizing: border-box;
	border-radius: 2px;
}

.extras-upselling .item {
	position: relative;
}

.modal-extras .item:hover {
	border-bottom-width: 3px;
	padding-bottom: 0;
	border-bottom-color: var(--resaltado);
}

.modal-venue .item:hover {
	border-color: var(--resaltado);
}

.modal-venue .item {
	justify-content: flex-start;
	border-radius: 5px;
	border: 0;
	padding: 0;
	margin: 0;
	color: unset;
	cursor: unset;
}

.modal-venue .item-image,
.modal-extras .item-image {
	width: 90px;
	height: 90px;
	margin: 10px 10px 10px auto;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	margin-right: 1em;
}

.modal-extras .item-image {
	margin: 0 0 0 auto;
}

.modal-extras .item:hover .item-image {
	border-bottom: 2px solid var(--resaltado);
	height: calc(100% + 2px);
	background-size: auto calc(100% + 2px);
	background-position: center 0;
}

.modal-venue .slide-inner {
	padding: 25px;
}

.preorder .modal-content-area .modal-venue .item-image {
	margin-left: 0;
	margin-top: 0;
	margin-bottom: 0;
	min-height: 148px;
	min-width: 150px;
	width: 100%;
	background-position: center;
	background-size: cover;
	border-radius: 5px 5px 0 0;
}

.preorder .modal-venue .carousel-container {
	height: 400px;
}

.modal-venue .item-image + div {
	display: flex;
	flex-direction: column;
}

.modal-extras .item-image {
	width: unset;
	height: unset;
	min-width: 90px;
	min-height: 90px;
}

.modal-venue .item > div:first-child,
.modal-extras .item > div:first-child {
	display: flex;
	flex-direction: column;
	padding-right: 1em;
}

.modal-venue .item > div:first-child {
	padding-right: 0;
}

.modal-venue .item h3,
.modal-extras .item h3 {
	margin-top: 1em;
	text-transform: uppercase;
	font-size: 15px;
	line-height: 1;
	font-weight: 600;
	color: #333;
}

.modal-extras .item h3 {
	margin-bottom: 0;
}

.modal-extras.extras-upselling h3 {
	padding: 0 15px 0;
	margin-top: 0;
}

.modal-venue .item h3 {
	margin: 15px 15px 0;
	padding: 0;
}

.modal-venue .description-text {
	margin-bottom: auto;
	padding: 10px 27px;
	background: var(--resaltado-claro);
	font-size: 0.9rem;
	color: var(--gris-texto);
	border-radius: 5px;
}

.modal-venue .description-text h3 {
	text-align: left;
	margin: 10px 0;
}

.modal-venue .item h3 + div {
	line-height: 1.2;
	max-height: 73px;
	overflow: hidden;
	margin: 5px 15px 15px;
}

.modal-venue .item .item-price,
.modal-venue .item .item-time {
	display: flex;
	align-items: center;
	text-transform: uppercase;
	margin-right: 1em;
	font-size: 12px;
	font-weight: 600;
}

.modal-extras.extras-upselling .item:hover,
.modal-extras.extras-upselling .item {
	border: 1px solid var(--resaltado-transparente);
	flex-direction: column;
	justify-content: flex-start;
	height: auto;
	padding: 0;
}

.modal-extras.extras-upselling .item > div {
	padding: 15px 0 0 0;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.modal-content-area .modal-extras.extras-upselling .item:hover .item-image,
.modal-content-area .modal-extras.extras-upselling .item-image {
	min-height: 160px;
	border: none;
	background-size: cover;
	background-position: center;
	width: 100%;
}

.preorder .modal-content-area .modal-extras .item-description {
	padding-right: 0;
}

.modal-extras.extras-upselling .item:hover .item-qty-selector,
.modal-extras.extras-upselling .item-qty-selector {
	margin: auto 0 0;
	width: 100%;
	padding: 5px 15px;
	border-top: 1px solid lightgrey;
	background-color: white;
	color: #292929;
}

.modal-venue .item .quantity {
	position: absolute;
	right: 5px;
	top: 5px;
	background: var(--resaltado);
	color: white;
	border-radius: 15px;
	padding: 5px 15px;
	font-weight: bold;
	font-size: 13px;
}

.modal-venue .bottom-details {
	display: flex;
	align-items: center;
	margin-top: auto;
	margin-bottom: 0.75em;
}

.modal-venue .bottom-details .details-tag {
	text-transform: uppercase;
	font-size: 11px;
	margin-right: 5px;
	display: inline-flex;
	padding: 2px 7px;
	border-radius: 5px;
	background-color: var(--resaltado);
	color: white;
	justify-content: space-evenly;
	align-items: center;
	font-weight: bold;
	margin-top: 5px;
}

.modal-extras .item-price {
	font-size: 14px;
}

.modal-extras.extras-upselling .item-price {
	font-size: 1rem;
	color: #292929;
	margin-left: auto;
}

.modal-extras {
	padding: 25px 25px 0 25px;
}

.modal-extras .category {
	margin-bottom: 25px;
}

.modal-extras .category-description {
	margin-bottom: 15px;
}

.preorder .modal-content-area .modal-extras .item-description {
	padding: 0;
	line-height: 1.2;
	font-size: 13px;
	padding-top: 15px;
	margin-bottom: auto;
	border: none;
	padding-right: 2em;
}

.preorder .modal-content-area .modal-extras.extras-upselling .item-description {
	padding: 15px 15px 20px;
}

.modal-extras .lower-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal-extras .item-qty-selector {
	display: flex;
	align-items: center;
	margin-top: 15px;
	margin-bottom: 10px;
	user-select: none;
}

.modal-extras .item:hover .item-qty-selector {
	margin-bottom: 8px;
}

.modal-extras .item-qty-selector .qty {
	background: var(--resaltado);
	color: white;
	border-radius: 5px;
	font-family: Circular, sans-serif;
	padding-bottom: 1px;
	min-width: 2em;
	font-weight: 700;
	margin-right: 0.5em;
	text-align: center;
}

.preorder .modal-upselling.page-extras .modal-content-area {
	height: calc(100% - 170px);
	padding-bottom: 25px;
}

.preorder .modal-upselling.page-availabilities .modal-venue .items {
    margin-top: 25px;
}

.preorder .modal-upselling.page-availabilities .modal-venue .carousel-container {
    padding-top: 5px;
}

.modal-extras.extras-upselling .item-qty-selector .qty {
	background: transparent;
	color: #292929;
	border: 0;
	font-size: 1rem;
	text-align: left;
	min-width: unset;
	padding: 0;
	margin: 0;
	font-weight: normal;
	display: inline-block;
}

.preorder .modal-extras.extras-upselling .item-price.muted {
	background-color: transparent;
	color: #292929;
}

.modal-extras .item-qty-selector .icon-svg-container {
	width: 30px !important;
	height: 30px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
}

.modal-extras .item-qty-selector .icon-svg-container:hover {
	background: #f6f6f6;
}

.modal-venue .event-description {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.modal-venue .event-description.iconos {
	padding-bottom: 15px;
	padding-top: 15px;
	margin-bottom: 10px;
	border-bottom: 1px solid #d3d3d8;
	display: flex;
	align-items: center;
}

.modal-venue .event-description .icon-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px 0;
}

.modal-venue div:hover > .icon-tooltip {
	right: unset;
}

.modal-venue .tags + .event-description .event-text {
	margin-top: 0;
}

.modal-venue .event-description .event-text p:last-child {
	margin-bottom: 1em;
}

.modal-venue .image-container {
	margin-bottom: 25px;
	box-shadow: 0px 4px 8px 0px rgb(27 28 36 / 8%);
	border-radius: 5px;
	cursor: pointer;
}

.slider-ficha-evento.slider-upselling {
	margin-top: 0;
	margin-bottom: 15px;
}

.slider.slider-upselling .slide h2 {
	margin-top: 1em;
	margin-bottom: 2em;
}

@media (max-width: 991px) {
	body .preorder .modal-inner {
		position: fixed;
		bottom: 0;
	}

	.modal-venue h1 {
		text-transform: uppercase;
		font-weight: 700;
		padding-bottom: 10px;
		font-family: Circular;
		margin: 0;
		font-size: 20px;
		line-height: 24px;
		text-align: left;
	}

	.preorder header.mobile.simple {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 2;
	}

	body.preorder {
		padding-bottom: 30px;
		padding-top: 60px;
		transition: padding-top 300ms;
	}

	body.preorder.scrolled {
		padding-top: 60px;
	}

	.preorder-custom-header {
		font-size: 15px;
		font-family: Circular;
		font-size: 20px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		border-bottom: 1px solid lightgrey;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background: white;
		z-index: 1;
	}

	.preorder .details-line.availability-description {
		margin-left: 0;
	}

	.preorder-selection-information {
		margin-top: 1em;
		padding-top: 1em;
		border-top: 1px solid lightgrey;
		padding-bottom: 1em;
	}

	.preorder-selection-information .item:hover,
	.preorder-selection-information .item {
		flex-direction: column;
		font-size: 13px;
		border: 0;
		border-bottom: 1px solid #f0f0f0;
		padding-bottom: 1em;
		margin-bottom: 1em;
		padding-top: 0;
	}

	.booking-information.upselling .preorder-selection-information .item {
        flex-direction: row;
    }

	.preorder-selection-information .item:last-child:hover,
	.preorder-selection-information .item:last-child {
		margin-bottom: 1em;
		border-bottom: 0;
	}

	.preorder-selection-information .item .title {
		font-weight: bold;
		color: #292929;
	}

	.preorder-selection-information .item .qty {
        font-weight: normal;
        color: #666;
    }

	.preorder-selection-information .item .details {
		display: flex;
		align-items: center;
	}

	.preorder-selection-information .item .details > div {
		margin-right: 1em;
	}

	.preorder-selection-information .item .details > div:last-child {
		margin-left: auto;
		margin-right: 0;
	}

	.booking-information.upselling .preorder-selection-information .item .details > div:last-child {
        display: flex;
        margin-right: 15px;
    }

	.preorder-selection-information .item .delete-button {
		position: absolute;
		right: 0;
		top: 0;
		width: 16px;
		height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		background-color: lightgrey;
		line-height: 16px;
	}

	.preorder-selection-information .item .extras {
		margin-top: 0.5em;
	}

	.preorder-selection-information .item .extra-row {
		display: flex;
		align-items: center;
	}

	.preorder-selection-information .item .extra-row > span:first-child {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid var(--resaltado);
		border-radius: 3px;
		width: 16px;
		height: 16px;
		font-size: 10px;
		margin-right: 10px;
	}

	.preorder-selection-information .item .extra-row > span {
		margin-right: 1em;
	}

	.preorder-selection-information .item .extra-row > span:last-child {
		margin-left: auto;
		margin-right: 0;
	}

	.modal-venue .items-container {
		overflow-x: scroll;
		overflow-y: hidden;
		scrollbar-width: 0;
		margin-bottom: 10px;
	}

	.modal-venue .items-container::-webkit-scrollbar {
		opacity: 0;
		display: none;
	}

	.modal-venue .items-container .carousel-mobile.items {
		display: flex;
		gap: 0;
		padding: 0;
	}

	.modal-venue .items-container .item {
		margin-right: 15px;
		flex-direction: column;
		border: transparent;
		border-width: 1px 1px 5px 1px;
		border-radius: 5px;
		height: auto;
	}

	.modal-venue .carousel-mobile .horizontal-scroll-container > div {
		padding: 0;
	}

	.modal-venue .items-container .item:first-child {
		margin-left: 3px;
	}

	.preorder .modal-content-area .modal-venue .item-description {
		border: none;
		margin: 0;
		padding: 0 0 0 15px;
	}

	.preorder .modal-content-area .modal-venue .item-image {
		width: 100%;
		height: 195px;
		overflow: hidden;
		margin: 0;
		background-size: cover;
		border-radius: 5px 5px 0 0;
	}

	.modal-venue .tags + .event-description {
		margin-top: 0.75em;
	}

	.modal-venue .event-description.mobile-only {
		padding-top: 10px;
		border-top: 1px solid #d3d3d8;
		border-bottom: none;
		margin-top: 15px;
	}

	.modal-venue .event-description.iconos {
		border-bottom: 1px solid #d3d3d8;
	}

	.preorder .modal-content-area .modal-venue .event-description .event-info {
		font-size: 14px;
	}

	.preorder .modal-content-area .modal-venue .item-descriptions .item-description {
		background: var(--resaltado-claro);
		box-shadow: none;
		border-width: 1px;
		margin-bottom: 30px;
		border-bottom: none;
		font-size: 15px;
		color: var(--gris-texto);
		padding: 20px 25px;
		box-shadow: none;
		border-radius: 5px;
		margin-bottom: 5px;
		line-height: 20px;
	}

	.preorder .modal-content-area .modal-venue .item-descriptions .item-description h2 {
		font-size: 15px;
		font-weight: 600;
		color: #333;
		text-transform: uppercase;
		margin-bottom: 0;
		margin-top: 0;
	}

	.preorder .modal-content-area .modal-venue .description-text {
		padding: 15px;
		background: var(--resaltado-claro);
		border-radius: 3px;
		color: var(--gris-texto);
		font-size: 0.9em;
	}

	.preorder .modal-calendario .top-bar {
		flex-direction: column;
	}

	.preorder .modal-calendario .pax-selector,
	.preorder .modal-calendario .selection-length-selector {
		width: 100%;
		border-radius: 3px;
	}

	.preorder .modal-calendario .pax-selector {
		margin-bottom: 5px;
	}

	.preorder .modal-calendario .selection-length-selector {
		border-left: 1px solid lightgrey;
	}

	.modal-extras.extras-upselling .items {
		grid-template-columns: 1fr;
	}

	.slider.slider-upselling .slides .slide .slide-inner {
		margin: 0;
	}

	.slider.slider-upselling .slide h1 {
		line-height: 1;
	}

	.slider.slider-upselling .call-to-action {
		display: flex;
	}

	.slider.slider-upselling .btn-slider {
		max-width: unset;
		width: unset;
		margin: 0 auto 0 0;
	}
}
