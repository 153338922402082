.admin-page.admin-reservas .combo-select .options,
.admin-page.admin-reservas .combo-select .selected-option {
	min-width: 253px;
	max-width: 253px;
}

.admin-page.admin-reservas .combo-select .options {
	z-index: 3;
}

.admin-page.admin-reservas .combo-select .option .empty {
	color: #999;
}

.admin-page.admin-reservas .combo-select .selected-option {
	text-align: center;
	padding: 15px 0;
	height: 56px;
}

.admin-page.admin-reservas .header .left .btn {
	margin-right: 1em;
}

.admin-page.admin-reservas .header .left .btn.active,
.admin-page.admin-reservas .header .left .btn.active:hover {
	background-color: #292929;
	color: #f6f6f6;
	box-shadow: none;
}

.admin-page.admin-reservas .inner {
	padding-left: 25px;
	padding-right: 25px;
}

.admin-page.admin-reservas .availability-row-header {
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin: 0 0 10px 0;
	text-align: center;
	font-size: 0.8em;
    display: flex;
    align-items: center;
	cursor: pointer;
	color: #333;
	text-transform: uppercase;
	height: 56px;
	user-select: none;
	background-color: #fcfcfc;
} 

.admin-page.admin-reservas .availability-row-header.crear-disponibilidad-button {
	background-color: white;
	justify-content: flex-end;
}

.admin-page.admin-reservas .availability-row-header > div {
	padding: 10px 20px;
    border-right: 1px solid #efefef;
    font-size: 13px;
}

.admin-page.admin-reservas .availability-row-header .fecha {
	margin-right: auto;
}

.admin-page.admin-reservas .availability-row-header .duracion {
	text-transform: lowercase;
}

.admin-page.admin-reservas .availability-row-header .precio {
}

.admin-page.admin-reservas .availability-row-header .pax {
}

.admin-page.admin-reservas .availability-row-header .expand-button {
	width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.admin-page.admin-reservas .availability-row-header.active,
.admin-page.admin-reservas .availability-row-header.active:hover {
	margin-bottom: 0;
	border-bottom: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-color: #292929;
}

.admin-page.admin-reservas .availability-row-header:hover {
	background-color: #f6f6f6;
}

.admin-page.admin-reservas .availability-row-details {
	overflow-y: auto;
	overflow-x: hidden;
	border-radius: 0 0 5px 5px;
	border: 1px solid #292929;
	border-top-width: 0;
	display: flex;
	margin-bottom: 10px;
	padding-bottom: 90px;
    position: relative;
	padding: 25px 25px 90px 25px;
    border-top: 1px solid rgb(230, 230, 230);
}

.admin-page.admin-reservas .availability-actions {
	position: absolute;
    bottom: 15px;
    right: 15px;
}

.admin-page.admin-reservas .availability-actions button {
	margin-left: 15px;
}

.admin-page.admin-reservas .availability-row-details .accordion-tab-container,
.admin-page.admin-reservas .availability-row-details .plus-wide {
	margin-left: 0;
	margin-right: 0;
}

.admin-page.admin-reservas .availability-row-details .horarios {
	padding-right: 15px;
	padding-bottom: 15px;
	position: relative;
	flex: 0.5;
	padding-left: 30px;
    margin-left: 25px;
    border-left: 1px solid lightgrey;
}

.admin-page.admin-reservas .availability-row-details .weekday-row {
	position: relative;
	display: flex;
	flex-direction: column;
}

.admin-page.admin-reservas .availability-row-details .weekday-header {
	display: flex;
	margin-top: 10px;
}

.admin-page.admin-reservas .availability-row-details .weekday-header > div {
	display: inline-block;
	text-align: right;
}

.admin-page.admin-reservas .availability-row-details .weekday-name {
	margin-right: auto;
	font-weight: bold;
	position: absolute;
	top: 28px;
}

@media(max-width: 1300px) {
	.admin-page.admin-reservas .availability-row-details .weekday-name {
		position: unset;
	}
}

.admin-page.admin-reservas .availability-row-details .time-start-label {
	margin-right: 10px;
	margin-left: auto;
}

.admin-page.admin-reservas .availability-row-details .time-start-label,
.admin-page.admin-reservas .availability-row-details .time-end-label {
	font-size: 0.75em;
}

.admin-page.admin-reservas .availability-row-details .time-start-label,
.admin-page.admin-reservas .availability-row-details .time-end-label {
	width: 8em;
	text-align: center;
	opacity: 0;
	animation: timeinputfadeleft 300ms forwards;
}

.admin-page.admin-reservas .availability-row-details .time-end-label {
	margin-right: 27px;
}

.admin-page.admin-reservas .availability-row-details .time-start {
	margin-left: auto;
	margin-right: 11px;
}

.admin-page.admin-reservas .availability-row-details .time-start,
.admin-page.admin-reservas .availability-row-details .time-end {
	position: relative;
	margin-right: 25px;
	text-align: center;
}

.admin-page.admin-reservas .availability-row-details .time-start .options-list,
.admin-page.admin-reservas .availability-row-details .time-end .options-list {
	text-align: center;
}

.admin-page.admin-reservas .availability-row-details .time-start + .time-end {
	margin-left: -15px;
}

.admin-page.admin-reservas .availability-row-details .time-input {
	border: none;
	opacity: 0;
	animation: timeinputfadeleft 300ms forwards;
}

@keyframes timeinputfadeleft {
	0% { transform: translateX(10px); opacity: 0; }
	100% { transform: translateX(0); opacity: 1; }
}

.admin-page.admin-reservas .slot-row-button {
	width: 1.5em;
	height: 1.5em;
	border-radius: 0.75em;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: -0.25em;
	user-select: none;
}

.admin-page.admin-reservas .delete-slot-row-button {
	right: -1.55em;
}

.admin-page.admin-reservas .slot-row-button span {
	transform: scale(0.8);
	opacity: 0.75;
}

.admin-page.admin-reservas .slot-row-button:hover span {
	opacity: 1;
}

.admin-page.admin-reservas .slot-row {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 5px;
}

.admin-page.admin-reservas .add-slot-row-button {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-weight: bold;
	color: var(--resaltado);
	font-size: 0.8em;
	margin: 5px 35px 5px 0;
	cursor: pointer;
}

.admin-page.admin-reservas .add-slot-row-button img {
	display: inline-block;
	width: 0.75em;
	height: 0.75em;
	margin-right: 0.5em;
	margin-top: 0.15em;
}

.admin-page.admin-reservas .availability-row-details .detalles {
	flex: 1;
	padding: 0 15px 80px 15px;
}

.admin-page.admin-reservas .availability-row-details .detalles .row {
	margin: 0;
}

.admin-page.admin-reservas .plazas-row {
	justify-content: space-between;
}

.admin-page.admin-reservas .availability-row-details .combo-mode-button {
	justify-content: flex-start;
	padding-left: 20%;
	opacity: 0;
	animation: timeinputfadeleft 300ms forwards;
}

.admin-page.admin-reservas .availability-row-details .plazas {
	display: flex;
	align-items: center;
}

.admin-page.admin-reservas .availability-row-details .plazas,
.admin-page.admin-reservas .availability-row-details .precio {
	flex: 1;
}

.admin-page.admin-reservas .availability-row-details .plazas .plus-minus {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-left: auto;
	margin-right: 1em;
	user-select: none;
}

.admin-page.admin-reservas .availability-row-details .plazas .plus-minus > div {
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	cursor: pointer;
	margin-left: 0.25em;
}

.admin-page.admin-reservas .availability-row-details .plazas .plus-minus > div:hover {
	background-color: #f6f6f6;
}

.admin-page.admin-reservas .availability-row-details .plazas .plus-minus img {
	width: 18px;
	height: 18px;
}

.admin-page.admin-reservas .availability-row-details .plazas-row {
	border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 9px 15px;
}

.admin-page.admin-reservas .availability-row-details .plazas-row .separator {
	margin: -9px auto;	
	border-left: 1px solid lightgrey;
	width: 1px;
}

.admin-page.admin-reservas .availability-row-details .plazas input {
	border: none;
	text-align: right;
	margin-right: 0.35em;
	width: 2em;
	background-color: var(--resaltado-claro);
}

.admin-page.admin-reservas .availability-row-details .plazas input:focus {
	outline: none;
}

.admin-page.admin-reservas .availability-row-details .precio {
	display: flex;
    align-items: center;
    justify-content: center;
}

.admin-page.admin-reservas .availability-row-details .precio input {
	border: none;
	text-align: right;
	width: 2em;
	margin-right: 0.35em;
	background-color: var(--resaltado-claro);
}

.admin-page.admin-reservas .availability-row-details .precio input:focus {
	outline: none;
}
