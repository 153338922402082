
.result-message {
	line-height: 22px;
	font-size: 16px;
	padding-top: 12px;
	text-align: center;
}
.result-message a {
	color: rgb(89, 111, 214);
	font-weight: 600;
	text-decoration: none;
}
.hidden {
	display: none !important;
}
.card-error {
	color: rgb(105, 115, 134);
	font-size: 16px;
	line-height: 20px;
	padding-top: 12px;
	text-align: center;
}
#card-element {
	border-radius: 4px 4px 0 0;
	padding: 12px;
	border: 1px solid rgba(50, 50, 93, 0.1);
	max-height: 44px;
	width: 100%;
	background: white;
	box-sizing: border-box;
}
#payment-request-button {
	margin-bottom: 32px;
}


.spinner {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin: 0 auto;
}

.spinner.block {
	display: block;
}

.spinner:after {
	content: " ";
	display: block;
	width: 32px;
	height: 32px;
	margin: 4px;
	border-radius: 50%;
	animation: spinner 1.2s linear infinite;
	border: 3px solid #000;
	border-color: #000 transparent #000 transparent;
}

.spinner.yellow:after {
	border: 3px solid var(--resaltado);
	border-color: var(--resaltado) transparent var(--resaltado) transparent;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

label[for="check"] {
	position: relative;
	height: 125px;
	width: 125px;
	display: inline-block;
	border: 2px solid rgba(255,255,255,0.2);
	border-radius: 50%;
	border-left-color: #5cb85c;
	animation: rotate 1.2s linear infinite;
	transform: scale(0.5) rotate(0);
}

@keyframes rotate {
	50% {
		border-left-color: #9b59b6;
	}

	75% {
		border-left-color: #e67e22;
	}

	100% {
		transform: scale(0.5) rotate(360deg);
	}
}

label[for="check"].checked .check-icon::after {
	position: absolute;
	content: " ";
	display: block;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateX(-10px) scaleX(-1) rotate(135deg) scale(0.85);
	height: 56px;
	width: 28px;
	border-top: 4px solid #5cb85c;
	border-right: 4px solid #5cb85c;
	transform-origin: left top;
	animation: check-icon 0.8s ease;
}

@keyframes check-icon {
	0% {
		height: 0;
		width: 0;
		opacity: 1;
	}
	20% {
		height: 0;
		width: 28px;
		opacity: 1;
	}
	40% {
		height: 56px;
		width: 28px;
		opacity: 1;
	}
	100% {
		height: 56px;
		width: 28px;
		opacity: 1;
	}
}

label[for="check"].checked {
	animation: none;
	border-color: #5cb85c;
	transition: border 0.5s ease-out;
}
