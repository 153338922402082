.custom-field input[type="email"],
.custom-field input[type="text"],
.custom-field input[type="number"],
.custom-field input[type="password"] {
	border: 1px solid #d7e0e2;
	margin-bottom: 10px;
	padding: 5px 10px;
	width: 100%;
}

.custom-field .number-field-container input[type="text"] {
	text-align: center;
	width: 50px;
	margin-bottom: 0;
}

.custom-field.type-text > div {
	height: 100%;
}

.custom-field textarea {
	width: 100%;
	height: 100%;
	border: 1px solid #d7e0e2;
	border-radius: 3px;
	padding: 5px 10px;
	min-height: 150px;
	resize: none;
}

.custom-field {
	margin-top: 0.5rem;
	position: relative;
}

.custom-field.type-currency {
	display: inline-block;
}

.custom-field .currency-field-container {
    position: relative;
}

.custom-field .currency-symbol {
	position: absolute;
	right: 0.5em;
	top: calc(50% - 0.3em);
	transform: translateY(-50%);
}

.custom-field .currency-symbol.placement-before {
	left: 0.5em;
	right: unset;
}

.custom-field input.currency {
	text-align: right;
}

.custom-field input.currency.symbol-before {
	text-align: left;
}

.custom-field label span {
	font-weight: 400;
	opacity: 0.8;
	font-size: 0.9em;
}

.custom-field .number-field-container {
	display: flex;
	align-items: center;
}

.custom-field .number-field-container .plus,
.custom-field .number-field-container .minus {
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	cursor: pointer;
	margin-left: 0.25em;
}

.custom-field .number-field-container .plus:hover,
.custom-field .number-field-container .minus:hover {
	background-color: #f6f6f6;
}

.custom-field .number-field-container .plus img,
.custom-field .number-field-container .minus img {
	width: 18px;
	height: 18px;
}

.custom-field .number-field-container input[type="text"],
.custom-field .number-field-container input[type="text"]:focus {
	border: none;
	outline: none;
}

.custom-field.type-date input {
	border: 1px solid lightgrey;
    border-radius: 3px;
    padding: 0px 5px;
}

.custom-field.type-date .calendar {
    position: fixed;
	z-index: 1;
    background: white;
    padding: 15px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 290px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-field.type-date .calendar .day {
	font-size: 12px;
	line-height: 12px;
}

.custom-field.type-date .calendar .day.today::after {
	margin-left: -1.5px;
	bottom: 2px;
	width: 3px;
	height: 3px;
}

.custom-field.type-date .calendar .month h4 {
	font-size: 13px;
	margin-top: 0;
}

.custom-field.type-date .date-value {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	text-transform: none;
	cursor: pointer;
	font-size: 13px;
}

.custom-field.type-date .date-value > span {
	margin-left: 5px;
}

.custom-field .max-length-indicator {
	position: absolute;
	background: white;
	bottom: 7px;
	right: 2px;
	padding: 2px 5px;
	font-size: 13px;
	border-radius: 3px 0 3px 0;
	pointer-events: none;
}

.custom-field .address-validation-popup {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    background: white;
    margin: -11px 0;
    border: 1px solid lightgrey;
    padding: 5px 10px;
}

.custom-field .address-validation-popup:hover,
.custom-field .address-validation-popup.active {
    background-color: var(--resaltado);
    color: white;
}
