.confirmation-dialog-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1021;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmation-dialog {
    background: white;
    border-radius: 3px;
    user-select: none;
}

.confirmation-dialog .prompt {
    padding: 2em;
    font-size: 15px;
}

.confirmation-dialog .buttons {
    display: flex;
    justify-content: space-evenly;
}

.confirmation-dialog .ok-button {
    border-radius: 0 0 0 3px;
}

.confirmation-dialog .cancel-button {
    border-radius: 0 0 3px 0;
}

.confirmation-dialog .buttons > div {
    flex: 1;
    text-align: center;
    padding: 0.75em 1em;
    border: 1px solid #efefef;
    border-width: 1px 1px 0 0;
    font-size: 13px;
}

.confirmation-dialog .buttons > div:active {
    background: #333;
    color: white;
    border-color: #333;
}